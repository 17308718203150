import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Grid as MuiGrid,
  Accordion,
  AccordionDetails,
  Typography as MuiTypography,
  Tooltip,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { spacing } from "@material-ui/system";
import { lineColors } from "../../../utils";
import DatePicker from "../../../components/pickers/DatePicker";
import Panel from "../../../components/panels/Panel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExportDataButton from "../../../components/graphs/ExportDataButton";
import SaveRefButton from "../../../components/graphs/SaveRefButton";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";
import TimeseriesLineChartDataViz from "../../../components/graphs/TimeseriesLineChartDataViz";
import { isWidthUp } from "@material-ui/core/withWidth";
// import { add } from "date-fns";

const fadeIn = keyframes`
  from {
    transform: scale(.25);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    transform: scale(1);
    opacity: 0;
  }

  to {
    transform: scale(.25);
    opacity: 1;
  }
`;

const OuterContainer = styled(Box)`
  margin-left: ${({ dynamicleftmargin }) => dynamicleftmargin};
  bottom: ${({ dynamicbottommargin }) => dynamicbottommargin};
  z-index: 3;
  position: absolute;
  max-height: 100%;
  width: ${({ dynamicleftmargin }) =>
    `calc(100% - ${dynamicleftmargin} - ${dynamicleftmargin})`};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Viz = styled.div`
  height: ${({ height }) => height};
  max-width: 100%;
`;

const TimeseriesWrapper = styled.div`
  height: calc(100% - 78px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: calc(${({ height }) => height} - 146px);
  width: 100%;
`;

const CircleMarker = styled.div`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const endpoint = {
  "Battery Data": "ui-graph-battery",
  "Water Temperature Data": "ui-graph-watertemps",
  Telemetry: "ui-graph-waterlevels-telemetry",
  "Winter Observations": "ui-graph-waterlevels-winterobs",
};

const DataViz = ({
  open = false,
  dataVizWellNumber,
  dataVizGraphType,
  onClose,
  width,
}) => {
  const divSaveRef = useRef(null);
  const graphSaveRef = useRef(null);
  //date filter defaults
  const defaultFilterValues = {
    startDate: "",
    endDate: "",
  };

  const [filterValues, setFilterValues] = useState(defaultFilterValues);
  const changeFilterValues = (name, value) => {
    setFilterValues((prevState) => {
      let newFilterValues = { ...prevState };
      newFilterValues[name] = value;
      return newFilterValues;
    });
  };

  const [currentSelectedTimeseriesData, setCurrentSelectedTimeseriesData] =
    useState(null);
  useEffect(() => {
    if (dataVizWellNumber && dataVizGraphType) {
      async function send() {
        if (dataVizGraphType === "Winter Observations") {
          changeFilterValues(
            "endDate",
            new Date().setFullYear(new Date().getFullYear() + 5)
          );
          changeFilterValues("startDate", null);
        } else {
          changeFilterValues(
            "endDate",
            new Date().setFullYear(new Date().getFullYear())
          );
          changeFilterValues(
            "startDate",
            new Date().setFullYear(new Date().getFullYear() - 2)
          );
        }
        try {
          const { data: results } = await axios.post(
            `${process.env.REACT_APP_ENDPOINT}/api/${endpoint[dataVizGraphType]}/${dataVizWellNumber}`
          );

          if (results.length) {
            setCurrentSelectedTimeseriesData(results);
          } else {
            setCurrentSelectedTimeseriesData(null);
          }
        } catch (err) {
          // Is this error because we cancelled it ourselves?
          if (axios.isCancel(err)) {
            console.log(`call was cancelled`);
          } else {
            console.error(err);
          }
        }
      }
      send().then();
    }
  }, [dataVizGraphType, dataVizWellNumber]); // eslint-disable-line

  const [filteredMutatedGraphData, setFilteredMutatedGraphData] = useState({});
  useEffect(() => {
    if (currentSelectedTimeseriesData?.length) {
      //mutate data for chartJS to use
      let graphData;
      if (dataVizGraphType === "Battery Data") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.collect_timestamp)
          ),
          datasets: [
            {
              label: "Daily Battery Levels",
              units: currentSelectedTimeseriesData[0].unit_desc,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.blue,
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.result_value
              ),
              pointRadius: 2,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
          ],
        };
      } else if (dataVizGraphType === "Water Temperature Data") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.collect_timestamp)
          ),
          datasets: [
            {
              label: "Average Daily Water Temperatures",
              units: currentSelectedTimeseriesData[0].unit_desc,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.blue,
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.result_value
              ),
              pointRadius: 2,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
          ],
        };
      } else if (dataVizGraphType === "Telemetry") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.collect_timestamp)
          ),
          datasets: [
            {
              label: "Depth to Water Telemetry",
              units: currentSelectedTimeseriesData[0].unit_desc,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: "rgba(67,99,216,.5)",
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.result_value
              ),
              fill: true,
              pointRadius: 2,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
          ],
        };
      } else if (dataVizGraphType === "Winter Observations") {
        graphData = {
          labels: currentSelectedTimeseriesData.map(
            (item) => new Date(item.collect_timestamp)
          ),
          datasets: [
            {
              label: "Trendline",
              units: currentSelectedTimeseriesData[0].unit_desc,
              type: "line",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.darkGray,
              borderColor: lineColors.darkGray,
              data: currentSelectedTimeseriesData.map(
                (item) => item.trendpoint
              ),
              pointRadius: 0,
              pointHoverRadius: 0,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
            {
              label: "Depth to Water Winter Observations",
              units: currentSelectedTimeseriesData[0].unit_desc,
              type: "bar",
              yAxisID: "yL",
              pointStyle: "point",
              backgroundColor: lineColors.blue,
              borderColor: lineColors.blue,
              data: currentSelectedTimeseriesData.map(
                (item) => item.result_value
              ),
              pointRadius: 2,
              pointHoverRadius: 4,
              borderWidth: 3,
              spanGaps: false,
              hidden: false,
            },
          ],
        };
      }
      setFilteredMutatedGraphData(graphData);
    } else {
      setFilteredMutatedGraphData(null);
    }
  }, [currentSelectedTimeseriesData]); //eslint-disable-line

  const formatTableTitle = (graphType) => {
    if (
      [
        "Battery Data",
        "Water Temperature Data",
        "Telemetry",
        "Winter Observations",
      ].includes(graphType)
    ) {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            <strong>{currentSelectedTimeseriesData[0].well_id ?? "NA"}</strong>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>{currentSelectedTimeseriesData[0].parameter ?? "NA"}</Box>
          </Typography>
        </>
      );
    } else {
      return null;
    }
  };

  const [dataVizHeight, setDataVizHeight] = useState({
    viz: isWidthDown("sm", width) ? "360px" : "460px",
    timeSeries: isWidthDown("sm", width) ? "465px" : "500px",
  });

  const handleExpand = () => {
    let newState = { ...dataVizHeight };
    if (
      ["460px", "360px"].includes(newState.viz) &&
      ["465px", "500px"].includes(newState.timeSeries)
    ) {
      newState.viz = isWidthDown("sm", width) ? "60vh" : "70vh";
      newState.timeSeries = isWidthDown("sm", width) ? "600px" : "860px";
    } else {
      newState.viz = isWidthDown("sm", width) ? "360px" : "460px";
      newState.timeSeries = isWidthDown("sm", width) ? "465px" : "500px";
    }
    setDataVizHeight(newState);
  };

  const adjustHeight = () => {
    let newState = { ...dataVizHeight };
    if (
      ["460px", "360px"].includes(newState.viz) &&
      ["465px", "500px"].includes(newState.timeSeries)
    ) {
      newState.viz = isWidthDown("sm", width) ? "360px" : "460px";
      newState.timeSeries = isWidthDown("sm", width) ? "465px" : "500px";
    } else {
      newState.viz = isWidthDown("sm", width) ? "60vh" : "70vh";
      newState.timeSeries = isWidthDown("sm", width) ? "600px" : "860px";
    }
    setDataVizHeight(newState);
  };

  useEffect(() => {
    adjustHeight();
  }, [width]); //eslint-disable-line

  return (
    <OuterContainer
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      open={open}
      dynamicleftmargin={isWidthUp("sm", width) ? "49px" : "10px"}
      dynamicbottommargin={isWidthUp("sm", width) ? "49px" : "28px"}
    >
      <Viz height={dataVizHeight.viz}>
        <CloseContainer>
          <ExpandButton
            handleExpand={handleExpand}
            expanded={!["460px", "360px"].includes(dataVizHeight.viz)}
          />
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={onClose}
              style={{ marginLeft: "4px" }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </CloseContainer>
        {filteredMutatedGraphData && currentSelectedTimeseriesData && (
          <Panel overflowY="scroll" overflowX="hidden">
            <TimeseriesContainer
              height={dataVizHeight.timeSeries}
              ref={divSaveRef}
            >
              <Box ml={4} pt={2} pb={2} display="flex" alignItems="center">
                {isWidthUp("sm", width) && (
                  <CircleMarker>
                    <Room />
                  </CircleMarker>
                )}

                <Box flexDirection="column" display="flex">
                  {formatTableTitle(dataVizGraphType)}
                </Box>

                <Box
                  style={{ marginLeft: "auto" }}
                  data-html2canvas-ignore={"true"}
                  display="flex"
                >
                  <ExportDataButton
                    title="well_ndx"
                    data={currentSelectedTimeseriesData}
                    filterValues={filterValues}
                  />
                  <SaveRefButton
                    data-html2canvas-ignore
                    ref={divSaveRef}
                    title={dataVizWellNumber}
                  />
                </Box>
              </Box>

              <TimeseriesWrapper>
                <TimeseriesLineChartDataViz
                  data={filteredMutatedGraphData}
                  yLReverse={["Winter Observations", "Telemetry"].includes(
                    dataVizGraphType
                  )}
                  beginAtZero={dataVizGraphType === "Telemetry"}
                  yLLabel={currentSelectedTimeseriesData[0].unit_desc}
                  xLabelFormat={
                    dataVizGraphType === "Winter Observations"
                      ? "YYYY"
                      : "MMM DD YYYY"
                  }
                  reverseLegend={true}
                  enableLegendClick={true}
                  ref={graphSaveRef}
                  tooltipFormat="MM-DD-YYYY"
                  filterValues={filterValues}
                  type="line"
                  displayLegend={true}
                  xLabelUnit={
                    dataVizGraphType === "Winter Observations" ? "year" : "day"
                  }
                  maxTicksX={isWidthUp("sm", width) ? 12 : 6}
                  maxTicksYL={6}
                  maxTicksYR={5}
                  align="start"
                />
              </TimeseriesWrapper>
            </TimeseriesContainer>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="map"
                id="map"
              >
                <Typography variant="h4">Filters</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: "block" }}>
                <Grid container spacing={6} alignItems="center">
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      size={isWidthDown("xs", width) ? "small" : "medium"}
                      label="Start Date"
                      name="startDate"
                      selectedDate={filterValues.startDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      size={isWidthDown("xs", width) ? "small" : "medium"}
                      label="End Date"
                      name="endDate"
                      selectedDate={filterValues.endDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Panel>
        )}
      </Viz>
    </OuterContainer>
  );
};

export default withWidth()(DataViz);
