import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  withWidth,
  isWidthDown,
  Grid as MuiGrid,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import styled from "styled-components/macro";
import React from "react";
import { spacing } from "@material-ui/system";

const Grid = styled(MuiGrid)(spacing);

const SearchRadiusControl = ({
  bufferValues,
  onBufferValuesChange,
  onClearBuffers,
  onResetBuffers,
  width,
  addBuffersToMap,
  map,
}) => {
  const handleClearBuffers = (event) => {
    event.preventDefault();
    onClearBuffers();
  };

  const radiusLookup = {
    "Class A": 150,
    "Class B": 250,
    "Class C": 400,
    "Class D": 500,
    "Class S": 50,
    DISABLE: 0.1,
  };

  return (
    <Box p={1}>
      <Box my={1} width="100%">
        <form onSubmit={handleClearBuffers}>
          <Box>
            <Grid
              container
              spacing={isWidthDown("sm", width) ? 2 : 1}
              alignItems="center"
              my={isWidthDown("sm", width) ? 1 : 4}
            >
              <Grid item xs={7} md={4}>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ width: "100%" }}
                >
                  <InputLabel>Label</InputLabel>
                  <Select
                    id="buffer1-label"
                    name="label"
                    value={bufferValues.buffer1.label} // Use the selectedOption if available, otherwise fallback to the original value
                    onChange={(event) => {
                      onBufferValuesChange(
                        event.target.name,
                        event.target.value,
                        "buffer1"
                      );
                      onBufferValuesChange(
                        "bufferAmount",
                        radiusLookup[event.target.value],
                        "buffer1"
                      );
                    }}
                    label="Select Option"
                  >
                    <MenuItem value="Class A">Class A</MenuItem>
                    <MenuItem value="Class B">Class B</MenuItem>
                    <MenuItem value="Class C">Class C</MenuItem>
                    <MenuItem value="Class D">Class D</MenuItem>
                    <MenuItem value="Class S">Class S</MenuItem>
                    <MenuItem value="DISABLE">{"DISABLE"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5} md={3}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>Radius</InputLabel>
                  <OutlinedInput
                    disabled
                    label="Radius"
                    id="buffer1-bufferAmount"
                    name="bufferAmount"
                    onChange={(event) => {
                      onBufferValuesChange(
                        event.target.name,
                        event.target.value,
                        "buffer1"
                      );
                    }}
                    value={
                      bufferValues.buffer1.bufferAmount === 0.1
                        ? 0
                        : bufferValues.buffer1.bufferAmount
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7} md={4}>
                <FormControl
                  size="small"
                  variant="outlined"
                  style={{ width: "100%" }}
                >
                  <InputLabel>Units</InputLabel>
                  <Select
                    disabled
                    label="Units"
                    labelId="buffer1-units-label"
                    id="buffer1-units"
                    name="units"
                    value={bufferValues.buffer1.units}
                    onChange={(event) => {
                      onBufferValuesChange(
                        event.target.name,
                        event.target.value,
                        "buffer1"
                      );
                    }}
                  >
                    <MenuItem value="yards">Yards</MenuItem>
                    <MenuItem value="feet">Feet</MenuItem>
                    <MenuItem value="miles">Miles</MenuItem>
                    <MenuItem value="acres">Acres</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                xs={5}
                md={1}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <input
                  style={{ width: "100%", height: "36px" }}
                  type="color"
                  id="color"
                  name="color"
                  value={bufferValues.buffer1.color ?? ""}
                  onChange={(event) => {
                    onBufferValuesChange(
                      event.target.name,
                      event.target.value,
                      "buffer1"
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ marginTop: "4px" }}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>Lat</InputLabel>
                  <OutlinedInput
                    label="Lat"
                    id="lat"
                    name="lat"
                    onChange={(event) => {
                      onBufferValuesChange(
                        event.target.name,
                        event.target.value,
                        "buffer1"
                      );
                    }}
                    value={bufferValues.buffer1.lat}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6} style={{ marginTop: "4px" }}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>Lon</InputLabel>
                  <OutlinedInput
                    label="Lon"
                    id="lon"
                    name="lon"
                    onChange={(event) => {
                      onBufferValuesChange(
                        event.target.name,
                        event.target.value,
                        "buffer1"
                      );
                    }}
                    value={bufferValues.buffer1.lon}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size={isWidthDown("xs", width) ? "small" : "medium"}
                style={{
                  padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
                }}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                onClick={onResetBuffers}
                type="reset"
                variant="contained"
                size={isWidthDown("xs", width) ? "small" : "medium"}
                style={{
                  padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
                }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                disabled={
                  !(
                    !isNaN(parseFloat(bufferValues.buffer1.lat)) &&
                    !isNaN(parseFloat(bufferValues.buffer1.lon)) &&
                    parseFloat(bufferValues.buffer1.lat) >= -90 &&
                    parseFloat(bufferValues.buffer1.lat) <= 90 &&
                    parseFloat(bufferValues.buffer1.lon) >= -180 &&
                    parseFloat(bufferValues.buffer1.lon) <= 180
                  )
                }
                fullWidth
                onClick={() =>
                  addBuffersToMap({
                    map: map,
                    coordinates: [
                      +bufferValues.buffer1.lon,
                      +bufferValues.buffer1.lat,
                    ],
                  })
                }
                color="primary"
                variant="contained"
                size={isWidthDown("xs", width) ? "small" : "medium"}
                style={{
                  padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
                }}
              >
                Draw
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};

export default withWidth()(SearchRadiusControl);
