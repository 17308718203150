/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  FileText,
  Monitor,
  Users,
  Map,
  Tool,
  MousePointer,
  Crosshair,
} from "react-feather";

import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";
import Landing from "../pages/presentation/Landing";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import UsersHome from "../pages/users/UsersHome";
import EditUser from "../pages/users/EditUser";
import SiteSummaryTable from "../pages/dataAccess/reports/SiteSummaryTable";
import GpuSelect from "../pages/dataAccess/tools/GpuSelect";
import IntersectWells from "../pages/dataAccess/tools/IntersectWells";
import { Class } from "@material-ui/icons";
import Blank from "../pages/pages/Blank";
const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

//DATA ACCESS
//sub reports
const reportsRoutes = {
  header: "Data Access",
  id: "Reports",
  icon: <FileText />,
  children: [
    {
      path: "/data-access/tools/site-summary-table",
      name: "Site Summary Table",
      component: SiteSummaryTable,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const publicMapRoutes = {
  header: "Public Resources",
  id: "Interactive Map",
  icon: <Map />,
  path: "/",
  name: "Interactive Map",
  component: PublicMap,
};

const toolsRoutes = {
  id: "Power Tools",
  icon: <Tool />,
  children: [
    {
      internalLink: true,
      id: "GPU Select",
      path: "/public-resources/tools/gpu-select",
      name: "GPU Select",
      component: GpuSelect,
    },
    {
      internalLink: true,
      id: "Adjacent Wells",
      path: "/public-resources/tools/adjacent-wells",
      name: "Adjacent Wells",
      component: IntersectWells,
    },
  ],
};

const tutorialsRoutes = {
  id: "How To ...",
  icon: <Class />,
  open: true,
  children: [
    {
      externalLink: true,
      id: "Find My Well",
      path: "https://youtu.be/axYJ37H7454",
      name: "Find My Well",
      component: Blank,
    },
    {
      externalLink: true,
      id: "Exploring The Map",
      path: "https://youtu.be/3uxOiCibW-8",
      name: "Exploring The Map",
      component: Blank,
    },
    {
      externalLink: true,
      id: "Access State Documents",
      path: "https://youtu.be/dBs0o_3gK1k",
      name: "Access State Documents",
      component: Blank,
    },
    {
      externalLink: true,
      id: "The GPU Report",
      path: "https://youtu.be/BFq09sTNOV0",
      name: "The GPU Report",
      component: Blank,
    },
    {
      externalLink: true,
      id: "Well Buffering Tools and the Adjacent Wells Report",
      path: "https://youtu.be/pqk752siRhw",
      name: "Well Buffering Tools and the Adjacent Wells Report",
      component: Blank,
    },
    {
      externalLink: true,
      id: "Viewing Water Level Graphs",
      path: "https://youtu.be/EDBwj1n-FzA",
      name: "Viewing Water Level Graphs",
      component: Blank,
    },
  ],
};

const gpuSelectRoute = {
  id: "GPU Select",
  icon: <MousePointer />,
  path: "/public-resources/tools/gpu-select/:id",
  name: "GPU Select",
  component: GpuSelect,
};

const intersectWellsRoute = {
  id: "Adjacent Wells",
  icon: <Crosshair />,
  path: "/public-tools/tools/adjacent-wells/:lat?/:lon?/:distance?",
  name: "Adjacent Wells",
  component: IntersectWells,
};

const usersManagementRoutes = {
  header: "Admin",
  id: "Users Management",
  icon: <Users />,
  path: "/admin/users-management",
  name: "Users Management",
  component: UsersHome,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const userRoutes = {
  header: "Admin",
  id: "Users Management",
  icon: <Users />,
  path: "/admin/users-management/:id",
  name: "Users Management",
  component: EditUser,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/login",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  reportsRoutes,
  toolsRoutes,
  tutorialsRoutes,
  gpuSelectRoute,
  intersectWellsRoute,
  usersManagementRoutes,
  userRoutes,
  accountRoutes,
];

// Routes using the max content layout
export const dashboardMaxContentLayoutRoutes = [publicMapRoutes];

// Routes using the Auth layout
export const authLayoutRoutes = [];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  publicMapRoutes,
  toolsRoutes,
  tutorialsRoutes,
  reportsRoutes,
  usersManagementRoutes,
];
