import React from "react";
import styled from "styled-components/macro";

import { CircularProgress } from "@material-ui/core";

const Root = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

function Loader({ height = "40px", width = "40px" }) {
  return (
    <Root>
      <CircularProgress
        style={{ height: height, width: width, zIndex: 1000 }}
        m={2}
        color="secondary"
      />
    </Root>
  );
}

export default Loader;
