import React from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
  TextField,
} from "@material-ui/core";
import Panel from "../../components/panels/Panel";
import { useApp } from "../../AppProvider";
import Loader from "../../components/Loader";

import { Autocomplete } from "@material-ui/lab";
import useFetchData from "../../hooks/useFetchData";

import Isemail from "isemail";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

function UsersHome() {
  const { currentUser, doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [Permits] = useFetchData("list-permits");

  const { data: UserRoles } = useQuery(
    ["UserRoles", currentUser],
    async () => {
      if (!currentUser) return;
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-user-roles/${currentUser.isDeveloper}`,
          { headers }
        );
        const manipulatedData = {};
        data.forEach(
          (item) =>
            (manipulatedData[item.auth0_role_id] = item["auth0_role_name"])
        );
        return manipulatedData;
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading, error, refetch } = useQuery(
    ["users", currentUser, Permits],
    async () => {
      if (!currentUser || !Permits.length) return;
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/${currentUser.isDeveloper}`,
          { headers }
        );
        const newData = data.map((item) => {
          return {
            ...item,
            ...{
              associated_permits: item?.associated_permits?.map((i) =>
                Permits.find((permit) => permit.permit_ndx === i)
              ),
            },
          };
        });
        return newData;
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  function validateEmail(emailInput) {
    if (!emailInput) return false;
    return Isemail.validate(emailInput);
  }

  const editTableColumns = [
    {
      title: "User Id",
      field: "auth0_user_id",
      editable: "never",
      hidden: true,
    },
    {
      title: "Full-name",
      field: "auth0_name",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      validate: (rowData) =>
        !rowData.auth0_name
          ? {
              isValid: false,
              helperText: "Requires Full-name",
            }
          : true,
    },
    {
      title: "Nickname",
      field: "auth0_nickname",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      validate: (rowData) =>
        !rowData.auth0_nickname
          ? {
              isValid: false,
              helperText: "Requires Nickname",
            }
          : true,
    },
    {
      title: "Assigned Role",
      field: "assigned_role",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      initialEditValue: "rol_cikolvymCJWvAfOP",
      lookup: UserRoles,
    },
    {
      title: "Email",
      field: "auth0_email",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      validate: (rowData) =>
        !validateEmail(rowData?.auth0_email)
          ? {
              isValid: false,
              helperText: "Requires Email",
            }
          : true,
    },
    {
      title: "Password",
      field: "auth0_password",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      editable: "onAdd",
      render: () => "**********",
      validate: (rowData) =>
        !rowData?.auth0_password
          ? {
              isValid: false,
              helperText: "Requires Password",
            }
          : rowData?.auth0_password?.length < 8
          ? {
              isValid: false,
              helperText: "Must be at least 8 characters",
            }
          : true,
    },
    {
      title: "Associated Permits",
      field: "associated_permits",
      initialEditValue: [],
      cellStyle: {
        minWidth: 400,
        maxWidth: 400,
      },
      render: (rowData) =>
        rowData?.associated_permits
          ? rowData?.associated_permits
              ?.map((permit) => permit.permit_number)
              .join(", ")
          : "None",
      editComponent: (props) => {
        return (
          <Autocomplete
            // limitTags={3}
            size="small"
            multiple
            disableCloseOnSelect
            id="permits"
            name="permits"
            options={Permits}
            getOptionLabel={(option) => option?.permit_number}
            getOptionSelected={(option, value) =>
              option.permit_number === value.permit_number
            }
            filterSelectedOptions
            value={props?.value ?? []}
            onChange={(event, newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                color="primary"
                placeholder="Permits"
                variant="standard"
              />
            )}
          />
        );
      },
    },
  ];

  const handleAdd = (newData) => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
          {
            email: newData.auth0_email,
            name: newData.auth0_name,
            nickname: newData.auth0_nickname,
            password: newData.auth0_password,
            role: newData.assigned_role,
            permits: newData.associated_permits.map(
              (permit) => permit.permit_ndx
            ),
          },
          { headers }
        );

        await refetch();
        doToast("success", "This user was successfully created");
      } catch (err) {
        doToast("error", "Something went wrong");
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/${oldData.auth0_user_id}`,
            { headers }
          );
          await refetch();
          doToast("success", "This user was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = (newData, oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
            {
              id: newData.auth0_user_id,
              email: newData.auth0_email,
              name: newData.auth0_name,
              nickname: newData.auth0_nickname,
              role: newData.assigned_role,
              permits: newData.associated_permits.map(
                (permit) => permit.permit_ndx
              ),
            },
            { headers }
          );

          await refetch();
          doToast("success", "This user was updated in the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title="Users Management" />
      <Typography variant="h3" gutterBottom display="inline">
        Users Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Interactive Map
        </Link>
        <Typography>Users Management</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Panel>
            <TableWrapper>
              {data ? (
                <MaterialTable
                  id="Users Management"
                  columns={editTableColumns}
                  data={data}
                  editable={{
                    onRowDelete: handleDelete,
                    onRowAdd: handleAdd,
                    onRowUpdate: handleUpdate,
                  }}
                  localization={{
                    toolbar: { searchPlaceholder: "Search Users" },
                  }}
                  components={{
                    Container: (props) => <div {...props} />,
                  }}
                  isLoading={isLoading}
                  options={{
                    emptyRowsWhenPaging: false,
                    addRowPosition: "first",
                    pageSize: 30,
                    pageSizeOptions: [5, 10, 30, 60],
                    searchFieldAlignment: "left",
                    showTitle: false,
                    maxBodyHeight: "400px",
                  }}
                />
              ) : (
                <Loader />
              )}
            </TableWrapper>
          </Panel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UsersHome;
