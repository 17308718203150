import { useEffect, useState } from "react";
import { INIT_STYLE_VALUES } from "../../constants";

const useLayerStyles = ({
  onLayerStyleChange,
  isMapLoaded,
  filtersRawData: data,
}) => {
  const [styleValues, setStyleValues] = useState(INIT_STYLE_VALUES);

  useEffect(() => {
    if (data) {
      setStyleValues((prevState) => ({
        ...prevState,
        wellClass: {
          ...prevState.wellClass,
          paint: {
            "circle-color": [
              "match",
              ["get", "Well Class"],
              ...data.wellClass.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        wellType: {
          ...prevState.wellType,
          paint: {
            "circle-color": [
              "match",
              ["get", "Well Type"],
              ...data.wellType.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
        wellStatus: {
          ...prevState.wellStatus,
          loaded: true,
          paint: {
            "circle-color": [
              "match",
              ["get", "Well Status"],
              ...data.wellStatus.reduce((acc, val) => {
                acc.push([val.value]);
                acc.push(val.color);
                return acc;
              }, []),
              "#000000",
            ],
          },
        },
      }));
    }
  }, [data]);

  const [activeStyle, setActiveStyle] = useState(styleValues.wellStatus);

  const styleOptions = Object.entries(styleValues).map(([key, value]) => ({
    display: value.name,
    value: key,
  }));
  const handleActiveStyle = (name) => {
    setActiveStyle(styleValues[name]);
    onLayerStyleChange(styleValues[name]);
  };

  useEffect(() => {
    if (isMapLoaded && styleValues.wellStatus.loaded) {
      handleActiveStyle("wellStatus");
    }
  }, [styleValues, isMapLoaded]); //eslint-disable-line

  return {
    activeStyle,
    handleActiveStyle,
    styleOptions,
    styleValues,
  };
};

export default useLayerStyles;
