import React from "react";
import { useQueries } from "react-query";

import {
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Grid as MuiGrid,
  Paper,
  Chip,
} from "@material-ui/core";

import styled from "styled-components/macro";

import Table from "../../../components/Table";
import { spacing } from "@material-ui/system";

import { Helmet } from "react-helmet-async";
import Loader from "../../../components/Loader";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import GpuSelectMap from "../../../components/map/GpuSelectMap";

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 600px;
`;

const Grid = styled(MuiGrid)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

const MapContainer = styled.div`
  height: 600px;
  width: 100%;
`;

//388px
const GpuSelect = () => {
  let { id } = useParams();
  id = id ?? "";

  const queries = [
    {
      queryKey: ["ui-map-gpus"],
      queryFn: async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/ui-map-gpus`
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      },
      refetchOnWindowFocus: false,
    },
    {
      queryKey: [`ui-map-wells-rev/${id}`],
      queryFn: async () => {
        try {
          const { data } = await axios.get(
            `${process.env.REACT_APP_ENDPOINT}/api/ui-map-wells-rev/${id}`
          );
          return data;
        } catch (err) {
          console.error(err);
        }
      },
      refetchOnWindowFocus: false,
    },
    // {
    //   queryKey: [`ui-gis-well-class-buffers/${id}`],
    //   queryFn: async () => {
    //     try {
    //       const { data } = await axios.get(
    //         `${process.env.REACT_APP_ENDPOINT}/api/ui-gis-well-class-buffers/${id}`
    //       );
    //       return data;
    //     } catch (err) {
    //       console.error(err);
    //     }
    //   },
    //   refetchOnWindowFocus: false,
    // },
  ];

  const results = useQueries(queries, {
    waitForAll: true,
  });

  const dataPolygons = results[0].data;
  const dataPoints = results[1].data;
  const dataBuffers = results[1].data;

  const isLoading = results.some((query) => query.isLoading);
  const error = results.find((query) => query.error);

  if (error) return "An error has occurred: " + error.message;

  const tabColumns = [
    {
      title: "GPU",
      field: "gpu_number",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
      render: (rowData) => {
        return (
          <Link
            color="secondary"
            target="_blank"
            rel="noreferrer"
            component={NavLink}
            to={`/public-resources/tools/gpu-select/${rowData.GPU}`}
          >
            {rowData.GPU}
          </Link>
        );
      },
    },
    {
      title: "Well",
      field: "Well",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Class",
      field: "Well Class",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
    },
    {
      title: "Type",
      field: "Well Type",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Status",
      field: "Well Status",
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Completion Date",
      field: "Completion Date",
      type: "date",
      filtering: false,
    },
    {
      title: "Max Yield (GPM)",
      field: "Max Yield (GPM)",
      filtering: false,
      cellStyle: {
        minWidth: 150,
        maxWidth: 150,
      },
    },
    {
      title: "Latitude",
      field: "Latitude",
      render: (rowData) => {
        return rowData["Latitude"].toFixed(5);
      },
      filtering: false,
    },
    {
      title: "Longitude",
      field: "Longitude",
      render: (rowData) => {
        return rowData["Longitude"].toFixed(5);
      },
      filtering: false,
    },
    {
      title: "Remarks",
      field: "Remarks",
      filtering: false,
      cellStyle: {
        minWidth: 600,
        maxWidth: 600,
      },
    },
    {
      title: "State Links",
      field: "State Links",
      filtering: false,

      render: (rowData) => {
        if (!rowData["State Links"]) return "";
        const splitArray = rowData["State Links"].split(",");
        return splitArray.map((item, i) => {
          const label = item.split(";")[0];
          const url = item.split(";")[1];
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "#5A6373",
                  marginRight: "8px",
                  fontSize: "12px",
                  width: "20px",
                  height: "20px",
                }}
              >
                {i + 1}
              </Avatar>

              <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
              >
                {label}
              </Link>
            </div>
          );
        });
      },
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
    },
    {
      title: "File Attachments",
      field: "File Attachments",
      filtering: false,

      render: (rowData) => {
        if (!rowData["File Attachments"]) return "";
        const splitArray = rowData["File Attachments"].split(",");
        return splitArray.map((item, i) => {
          const label = item.split(";")[0];
          const url = item.split(";")[1];
          return (
            <div
              key={i}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "4px",
              }}
            >
              <Avatar
                style={{
                  backgroundColor: "#5A6373",
                  marginRight: "8px",
                  fontSize: "12px",
                  width: "20px",
                  height: "20px",
                }}
              >
                {i + 1}
              </Avatar>

              <Link
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                color="secondary"
              >
                {label}
              </Link>
            </div>
          );
        });
      },
      cellStyle: {
        minWidth: 250,
        maxWidth: 250,
      },
    },
  ];

  return (
    <>
      <Helmet title="GPU Select Tool" />
      <Typography variant="h3" gutterBottom display="inline">
        GPU Select Tool
      </Typography>

      {id && (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} exact to="/">
            Interactive Map
          </Link>
          <Link
            component={NavLink}
            exact
            to="/public-resources/tools/gpu-select"
          >
            All GPU
          </Link>
          {id && dataPolygons && (
            <Chip
              variant="outlined"
              label={
                <Typography style={{ fontWeight: 500 }}>
                  {`${
                    dataPolygons.find((p) => p.gpu_number === parseInt(id))
                      .gpu_number
                  }${
                    dataPolygons.find((p) => p.gpu_number === parseInt(id))
                      .gpu_name
                      ? " - " +
                        dataPolygons.find((p) => p.gpu_number === parseInt(id))
                          .gpu_name
                      : ""
                  }`}
                </Typography>
              }
              color="secondary"
            />
          )}
        </Breadcrumbs>
      )}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} xl={6}>
          <MapContainer>
            {!isLoading ? (
              <GpuSelectMap
                buffers={dataBuffers}
                points={dataPoints}
                polygons={dataPolygons}
                gpu={
                  id
                    ? dataPolygons.find((p) => p.gpu_number === parseInt(id))
                    : null
                }
              />
            ) : (
              <Loader />
            )}
          </MapContainer>
        </Grid>

        <Grid item xs={12} xl={6}>
          <Paper style={{ padding: "0 6px" }}>
            <TableWrapper>
              {!isLoading ? (
                <Table
                  label="GPU Select Tool"
                  isLoading={isLoading}
                  columns={tabColumns}
                  data={dataPoints.filter(
                    (item) => item["Latitude"] && item["Longitude"]
                  )}
                  height="calc(100vh - 64px - 48px - 106px - 48px - 64px - 156px)"
                  pageSize={30}
                  options={{ filtering: true }}
                />
              ) : (
                <Loader />
              )}
            </TableWrapper>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default GpuSelect;
