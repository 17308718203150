import React from "react";
import { useQuery } from "react-query";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Grid as MuiGrid,
  Link,
  Typography as MuiTypography,
} from "@material-ui/core";
import Panel from "../../components/panels/Panel";
import { useApp } from "../../AppProvider";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Typography = styled(MuiTypography)(spacing);

function EditUser() {
  let { id } = useParams();
  const { currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, error } = useQuery(
    [id, currentUser],
    async () => {
      if (!currentUser) return;
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/${id}`,
          { headers }
        );
        console.log(data);
        return data;
      } catch (err) {
        // Is this error because we cancelled it ourselves?
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <React.Fragment>
      <Helmet title="Contacts Management" />
      <Typography variant="h3" gutterBottom display="inline">
        User Management
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to="/">
          Interactive Map
        </Link>
        <Link component={NavLink} exact to="/admin/users-management">
          Users Management
        </Link>
        <Typography>User</Typography>
      </Breadcrumbs>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Panel>
            {data &&
              !isLoading &&
              !error &&
              Object.entries(data).map(([key, value], i) => (
                <Typography key={i}>{`${key}: ${value}`}</Typography>
              ))}
          </Panel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EditUser;
