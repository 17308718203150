import {
  Box,
  FormControl,
  Select,
  MenuItem,
  Button,
  withWidth,
  isWidthDown,
  Grid as MuiGrid,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import styled from "styled-components/macro";
import React from "react";
import { spacing } from "@material-ui/system";

const Grid = styled(MuiGrid)(spacing);

const BufferInputRow = ({ bufferName, onChange, values, width }) => {
  return (
    <>
      <Grid
        container
        spacing={isWidthDown("sm", width) ? 2 : 1}
        alignItems="center"
        my={isWidthDown("sm", width) ? 1 : 4}
      >
        <Grid item xs={5}>
          <FormControl variant="outlined" size="small">
            <InputLabel>
              {values.units === "acres" ? "Area" : "Radius"}
            </InputLabel>
            <OutlinedInput
              label={values.units === "acres" ? "Area" : "Radius"}
              id={`${bufferName}-bufferAmount`}
              name="bufferAmount"
              onChange={(event) =>
                onChange(event.target.name, event.target.value, bufferName)
              }
              value={values.bufferAmount}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl
            size="small"
            variant="outlined"
            style={{ width: "100%" }}
          >
            <InputLabel>Units</InputLabel>
            <Select
              label="Units"
              labelId={`${bufferName}-units-label`}
              id={`${bufferName}-units`}
              name="units"
              value={values.units}
              onChange={(event) =>
                onChange(event.target.name, event.target.value, bufferName)
              }
            >
              <MenuItem value="yards">Yards</MenuItem>
              <MenuItem value="feet">Feet</MenuItem>
              <MenuItem value="miles">Miles</MenuItem>
              <MenuItem value="acres">Acres</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
          <input
            style={{ width: "100%", height: "36px" }}
            type="color"
            id="color"
            name="color"
            value={values.color ?? ""}
            onChange={(event) =>
              onChange(event.target.name, event.target.value, bufferName)
            }
          />
        </Grid>

        <Grid item xs={6} style={{ marginTop: "4px" }}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Lat</InputLabel>
            <OutlinedInput
              label="Lat"
              id="lat"
              name="lat"
              onChange={(event) => {
                onChange(event.target.name, event.target.value, bufferName);
              }}
              value={values.lat}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "4px" }}>
          <FormControl variant="outlined" size="small">
            <InputLabel>Lon</InputLabel>
            <OutlinedInput
              label="Lon"
              id="lon"
              name="lon"
              onChange={(event) => {
                onChange(event.target.name, event.target.value, bufferName);
              }}
              value={values.lon}
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

const AdjacentWellsControl = ({
  bufferValues,
  onBufferValuesChange,
  onClearBuffers,
  onResetBuffers,
  width,
  // intersectWellsEnabled,
  // setIntersectWellsEnabled,
  addBuffersToMap,
  map,
}) => {
  const handleClearBuffers = (event) => {
    event.preventDefault();
    onClearBuffers();
  };
  return (
    <Box p={1}>
      <Grid my={1} width="100%">
        <form onSubmit={handleClearBuffers}>
          <Box style={{ marginBottom: "6px" }}>
            <BufferInputRow
              width={width}
              bufferName="buffer1"
              onChange={onBufferValuesChange}
              values={bufferValues.buffer1}
            />
            {/*<FormControlLabel*/}
            {/*  style={{ marginLeft: "10px", marginBottom: "6px" }}*/}
            {/*  control={*/}
            {/*    <Checkbox*/}
            {/*      checked={intersectWellsEnabled}*/}
            {/*      onChange={() =>*/}
            {/*        setIntersectWellsEnabled(!intersectWellsEnabled)*/}
            {/*      }*/}
            {/*    />*/}
            {/*  }*/}
            {/*  label="Intersect Wells Report"*/}
            {/*/>*/}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                size={isWidthDown("xs", width) ? "small" : "medium"}
                style={{
                  padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
                }}
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                onClick={onResetBuffers}
                type="reset"
                variant="contained"
                size={isWidthDown("xs", width) ? "small" : "medium"}
                style={{
                  padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
                }}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                disabled={
                  !(
                    !isNaN(parseFloat(bufferValues.buffer1.lat)) &&
                    !isNaN(parseFloat(bufferValues.buffer1.lon)) &&
                    parseFloat(bufferValues.buffer1.lat) >= -90 &&
                    parseFloat(bufferValues.buffer1.lat) <= 90 &&
                    parseFloat(bufferValues.buffer1.lon) >= -180 &&
                    parseFloat(bufferValues.buffer1.lon) <= 180
                  )
                }
                fullWidth
                onClick={() =>
                  addBuffersToMap({
                    map: map,
                    coordinates: [
                      +bufferValues.buffer1.lon,
                      +bufferValues.buffer1.lat,
                    ],
                  })
                }
                color="primary"
                variant="contained"
                size={isWidthDown("xs", width) ? "small" : "medium"}
                style={{
                  padding: isWidthDown("xs", width) ? "4px 5px" : "6px 16px",
                }}
              >
                Report
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Box>
  );
};

export default withWidth()(AdjacentWellsControl);
