import React from "react";
import { IconButton, Box, Tooltip } from "@material-ui/core";
import Icon from "@material-ui/icons/Chat";

const PopupControl = ({ open = false, onClose, top = 94 }) => {
  return (
    <Box
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      position="absolute"
      zIndex={1}
      top={top}
      right={10}
      display="flex"
      flexDirection="column"
    >
      <Tooltip title="Toggle Map Popup">
        <IconButton
          size="small"
          color={open ? "secondary" : "default"}
          onClick={onClose}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default PopupControl;
