class PrintControl {
  onAdd(map) {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = document.createElement("button");
    icon.type = "button";
    icon.className = "material-icons";
    icon.style.verticalAlign = "middle";
    icon.style.cursor = "pointer";
    icon.textContent = "print";
    this._container.appendChild(icon);
    icon.addEventListener("click", () => {
      map.triggerRepaint(); // Trigger map rendering

      map.once("render", () => {
        const a = document.createElement("a");
        a.href = map.getCanvas().toDataURL();
        a.download = "map.png";
        document.body.appendChild(a);
        a.click();
      });
    });
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
  }
}

export default PrintControl;
