import React from "react";
import ReactDOM from "react-dom";
import { Tooltip } from "@mui/material";

class DragCircleControl {
  constructor(draw) {
    this.draw = draw;
  }
  onAdd() {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = (
      <button
        type="button"
        className="material-icons"
        style={{ verticalAlign: "middle", cursor: "pointer" }}
      >
        trip_origin
      </button>
    );

    const tooltipContent = (
      <Tooltip title="Circle Tool" arrow placement="left">
        <span onClick={this.handleClick}>{icon}</span>
      </Tooltip>
    );

    ReactDOM.render(tooltipContent, this._container);

    this._container.addEventListener("click", () => {
      this.draw.changeMode("draw_circle");
    });
    return this._container;
  }

  onRemove() {
    this._container.parentNode.removeChild(this._container);
  }
}

export default DragCircleControl;
