import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import styled, { ThemeProvider } from "styled-components/macro";
import ResetZoomControl from "./ResetZoomControl";
import ToggleBasemapControl from "./ToggleBasemapControl";
import debounce from "lodash.debounce";
import ReactDOM from "react-dom";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import createTheme from "../../theme";
import Popup from "../../pages/publicMap/popup";
import { create } from "jss";
import { useSelector } from "react-redux";
import { DEFAULT_MAP_CENTER } from "../../pages/publicMap/constants";
import {
  getPolygonCenter,
  InfoContainer,
} from "../../pages/publicMap/publicMap";
import PrintControl from "./PrintControl";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const toGeoJSON = ({ data, geometryField }) => {
  return {
    type: "FeatureCollection",
    features: data.map((d, i) => {
      const { [geometryField]: geometryProp, ...properties } = d;
      return {
        id: i,
        type: "Feature",
        geometry: d[geometryField],
        properties,
      };
    }),
  };
};

const GpuSelectMap = ({ buffers, points, polygons, gpu }) => {
  const theme = useSelector((state) => state.themeReducer);
  const gpuIdDisplayRef = useRef(null);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const [map, setMap] = useState();

  const wellClassBuffers = {
    type: "geojson",
    data: toGeoJSON({
      data: buffers,
      geometryField: "class_buffer_geometry",
    }),
  };

  const gpuLocations = {
    // id: "gpuLocations",
    type: "geojson",
    data: toGeoJSON({
      data: points,
      geometryField: "location_geometry",
    }),
  };

  const gpuPolygons = {
    type: "geojson",
    data: toGeoJSON({
      data: polygons,
      geometryField: "gpu_geometry",
    }),
  };

  const gpuPolygonsBuffers = {
    type: "geojson",
    data: toGeoJSON({
      data: polygons,
      geometryField: "gpu_inner_buffer_geometry",
    }),
  };

  const gpuCenter = gpu
    ? getPolygonCenter(gpu.gpu_geometry.coordinates)
    : DEFAULT_MAP_CENTER;

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "400px",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const mapContainer = useRef(null); // create a reference to the map container

  const DUMMY_BASEMAP_LAYERS = [
    { url: "streets-v11", icon: "commute", label: "Streets" },
    { url: "outdoors-v11", icon: "park", label: "Outdoors" },
    { url: "satellite-streets-v11", icon: "satellite_alt", label: "Satellite" },
  ];

  const pointsCircleLayer = {
    id: "pointsCircleLayer",
    name: "GPU Locations",
    type: "circle",
    source: "gpuLocations",
    paint: {
      "circle-stroke-width": 1,
      "circle-stroke-color": "white",
      "circle-radius": [
        "interpolate",
        ["exponential", 1.16],
        ["zoom"],
        8, // min zoom level
        ["get", "map_circle_radius"], // circle radius at min zoom
        13, // max zoom level
        8, // circle radius at max zoom
      ],
      "circle-opacity": gpu ? 1 : 0.5,
      "circle-color": [
        "case",
        [
          "any",
          ["==", ["get", "npgcd_well_class_ndx"], 21],
          ["==", ["get", "npgcd_well_class_ndx"], 999],
          ["==", ["get", "npgcd_well_status_ndx"], 4],
          ["==", ["get", "npgcd_well_status_ndx"], 5],
          ["==", ["get", "npgcd_well_status_ndx"], 6],
        ],
        "darkGray",
        "#0000FF",
      ],
    },
    lreProperties: {
      popup: {
        titleField: "Well",
        excludeFields: [
          "well_ndx",
          "npgcd_well_type_ndx",
          "npgcd_permit_status_ndx",
          "npgcd_well_status_ndx",
          "npgcd_monitoring_status_ndx",
          "npgcd_meter_requirement_ndx",
          "npgcd_permit_status_ndx",
          "Winter Observations",
          "Telemetry",
          "Battery Data",
          "Water Temperature Data",
          "location_geometry",
          "map_color",
          "map_circle_radius",
          "map_stroke_width",
          "map_stroke_color",
          "map_opacity",
          "layer_id",
          "location_geometry",
          "class_buffer_geometry",
          "tableData",
        ],
      },
    },
  };

  const pointsSymbolLayer = {
    id: "pointsSymbolLayer",
    name: "GPU Locations",
    type: "symbol",
    source: "gpuLocations",
    layout: {
      "text-field": ["get", "Well"],
      "text-size": 14,
      "text-offset": [0, -1.2],
      "text-font": ["literal", ["Roboto Black", "Arial Unicode MS Bold"]],
    },
    minzoom: 12,
    paint: {
      "text-color": "rgb(49,49,49)",
      "text-halo-color": "rgba(255,255,255,1)",
      "text-halo-width": 3,
    },
  };

  const buffersFillLayer = {
    id: "buffersFillLayer",
    name: "Groundwater Production Units (GPUs)",
    type: "fill",
    source: "wellClassBuffers",
    minzoom: 10,
    paint: {
      "fill-color": "#FFA500",
      "fill-opacity": 0.25,
    },
    lreProperties: {
      popup: {
        titleField: "Well Class",
        excludeFields: [
          "well_ndx",
          "npgcd_well_type_ndx",
          "npgcd_permit_status_ndx",
          "npgcd_well_status_ndx",
          "npgcd_monitoring_status_ndx",
          "npgcd_meter_requirement_ndx",
          "npgcd_permit_status_ndx",
          "Winter Observations",
          "Telemetry",
          "Battery Data",
          "Water Temperature Data",
          "location_geometry",
          "map_color",
          "map_circle_radius",
          "map_stroke_width",
          "map_stroke_color",
          "map_opacity",
          "layer_id",
          "location_geometry",
          "class_buffer_geometry",
          "tableData",
        ],
      },
    },
  };

  const buffersLineLayer = {
    id: "buffersLineLayer",
    name: "Groundwater Production Units (GPUs)",
    type: "line",
    source: "wellClassBuffers",
    minzoom: 10,
    paint: {
      "line-color": "#FFA500",
      "line-width": 1,
    },
  };

  const polygonsFillLayer = {
    id: "polygonsFillLayer",
    name: "Groundwater Production Units (GPUs)",
    type: "fill",
    source: "gpuPolygons",
    paint: {
      "fill-color": [
        "case",
        ["==", ["get", "gpu_number"], gpu ? gpu?.gpu_number : null],
        "yellow",
        "red",
      ],
      "fill-opacity": 0.1,
    },
    lreProperties: {
      popup: {
        titleField: "gpu_number",
        excludeFields: [
          "gpu_geometry",
          "gpu_number",
          "gpu_inner_buffer_geometry",
        ],
      },
    },
  };

  const polygonsLineLayer = {
    id: "polygonsLineLayer",
    name: "Groundwater Production Units (GPUs)",
    type: "line",
    source: "gpuPolygons",
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "gpu_number"], gpu ? gpu?.gpu_number : null],
        "yellow",
        "red",
      ],
      "line-width": [
        "case",
        ["==", ["get", "gpu_number"], gpu ? gpu?.gpu_number : null],
        4,
        2,
      ],
    },
  };

  const polygonsBuffersLineLayer = {
    id: "polygonsBuffersLineLayer",
    name: "GPU Buffers",
    type: "line",
    source: "gpuPolygonsBuffers",
    paint: {
      "line-color": [
        "case",
        ["==", ["get", "gpu_number"], gpu ? gpu?.gpu_number : null],
        "yellow",
        "red",
      ],
      "line-width": [
        "case",
        ["==", ["get", "gpu_number"], gpu ? gpu?.gpu_number : null],
        2,
        1,
      ],
      "line-dasharray": [1, 3],
    },
  };

  useEffect(() => {
    const tempMap = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/" + DUMMY_BASEMAP_LAYERS[0].url,
      center: gpuCenter,
      zoom: gpu ? 13 : 8,
    });

    tempMap.addControl(new mapboxgl.NavigationControl(), "top-left");
    tempMap.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      }),
      "top-left"
    );
    tempMap.addControl(new mapboxgl.FullscreenControl());
    // Add locate control to the map.
    tempMap.addControl(new ResetZoomControl(), "top-left");

    tempMap.addControl(new PrintControl(), "bottom-right");

    DUMMY_BASEMAP_LAYERS.forEach((layer) => {
      return tempMap.addControl(
        new ToggleBasemapControl(layer.url, layer.icon, layer.label)
      );
    });

    tempMap.on("load", () => {
      setMapIsLoaded(true);
      setMap(tempMap);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map.resize(), 100));
      resizer.observe(mapContainer.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && typeof map != "undefined") {
      if (!map.getSource("gpuLocations")) {
        map.addSource("gpuLocations", gpuLocations);
        map.addSource("gpuPolygons", gpuPolygons);
        map.addSource("gpuPolygonsBuffers", gpuPolygonsBuffers);
        map.addSource("wellClassBuffers", wellClassBuffers);
        // Add a layer showing the places.

        map.addLayer(polygonsBuffersLineLayer);
        map.addLayer(polygonsFillLayer);
        map.addLayer(polygonsLineLayer);
        map.addLayer(buffersFillLayer);
        map.addLayer(buffersLineLayer);
        map.addLayer(pointsCircleLayer);
        map.addLayer(pointsSymbolLayer);

        map.on("click", (e) => {
          map.fire("closeAllPopups");

          const features = map.queryRenderedFeatures(e.point);
          const myFeatures = features.filter((feature) =>
            [
              "polygonsFillLayer",
              "buffersFillLayer",
              "pointsCircleLayer",
            ].includes(feature.layer.id)
          );

          if (!myFeatures.length) return;
          const coordinates = [e.lngLat.lng, e.lngLat.lat];
          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
                <ThemeProvider theme={createTheme(theme.currentTheme)}>
                  <Popup
                    layers={[
                      pointsCircleLayer,
                      polygonsFillLayer,
                      buffersFillLayer,
                    ]}
                    features={myFeatures}
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        });

        const showInfo = (gpuId) => {
          if (gpuIdDisplayRef.current) {
            gpuIdDisplayRef.current.textContent = `GPU ID: ${gpuId}`;
            gpuIdDisplayRef.current.setAttribute("data-visible", "true");
          }
        };

        const hideInfo = () => {
          if (gpuIdDisplayRef.current) {
            gpuIdDisplayRef.current.textContent = "";
            gpuIdDisplayRef.current.setAttribute("data-visible", "false");
          }
        };

        map.on("mousemove", "polygonsFillLayer", (e) => {
          if (e.features.length > 0) {
            const gpuId = e.features[0].properties.gpu_number;
            showInfo(gpuId);
          } else {
            hideInfo();
          }
        });

        map.on("mouseleave", "polygonsFillLayer", hideInfo);

        // Change the cursor to a pointer when the mouse is over the places layer.
        map.on("mouseenter", "pointsCircleLayer", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change it back to a pointer when it leaves.
        map.on("mouseleave", "pointsCircleLayer", () => {
          map.getCanvas().style.cursor = "";
        });
      }
    }
  }, [mapIsLoaded, map]); //eslint-disable-line

  return (
    <Root>
      <MapContainer ref={mapContainer}>
        <InfoContainer ref={gpuIdDisplayRef} />
      </MapContainer>
    </Root>
  );
};

export default GpuSelectMap;
