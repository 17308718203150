// Control implemented as ES6 class
import React from "react";
import ReactDOM from "react-dom"; // Import ReactDOM
import { STARTING_LOCATION } from "../../../constants";
import { Tooltip } from "@mui/material";

class ResetZoomControl {
  constructor(map) {
    this.map = map;
    this.handleClick = this.handleClick.bind(this);
  }

  onAdd() {
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = (
      <button
        type="button"
        className="material-icons"
        style={{ verticalAlign: "middle", cursor: "pointer" }}
      >
        explore
      </button>
    );

    const tooltipContent = (
      <Tooltip title="Reset Map Extent" arrow>
        <span onClick={this.handleClick}>{icon}</span>
      </Tooltip>
    );

    ReactDOM.render(tooltipContent, this._container);

    return this._container;
  }

  handleClick() {
    // Handle click logic here
    this.map.flyTo({
      center: STARTING_LOCATION,
      zoom: 8.4,
      padding: { bottom: 0 },
    });
  }

  onRemove() {
    ReactDOM.unmountComponentAtNode(this._container);
    this._container.parentNode.removeChild(this._container);
  }
}

export default ResetZoomControl;
