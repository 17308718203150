import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails as MuiAccordionDetails,
  Box,
  Paper,
  Typography,
  withWidth,
  isWidthDown,
} from "@material-ui/core";
import LayersIcon from "@material-ui/icons/Layers";
import MapIcon from "@material-ui/icons/Map";
import SearchRadiusIcon from "@material-ui/icons/WifiTethering";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components/macro";
import LayersControl from "../layersControl";
import BasemapsControl from "../basemapsControl";
import SearchRadiusControl from "../searchRadiusControl";
import useFetchData from "../../../../hooks/useFetchData";
import { isWidthUp } from "@material-ui/core/withWidth";
import AdjacentWellsControl from "../adjacentWellsControl";
import { GroupWork, More } from "@material-ui/icons";

const Container = styled(Paper)`
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  left: 49px;
  position: absolute;
  top: 10px;
  max-height: calc(100% - 32px);
  overflow-x: hidden;
  overflow-y: scroll;
  width: ${({ width }) => width};
  z-index: 3;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-height: ${({ maxheight }) => maxheight};
  // height: ${({ maxheight }) => maxheight};
  overflow-x: hidden;
  overflow-y: auto;
`;

const MainControl = ({
  activeBasemap,
  basemaps,
  bufferValues,
  layers,
  onBasemapChange,
  filters,
  onBufferValuesChange,
  onClearBuffers,
  onLayerChange,
  onOpacityChange,
  onResetBuffers,
  onEnableSearchRadiusControl,
  value,
  activeStyle,
  width,
  bufferValuesAdjacentWells,
  onBufferValuesChangeAdjacentWells,
  onClearBuffersAdjacentWells,
  onResetBuffersAdjacentWells,
  onEnableSearchRadiusControlAdjacentWells,
  intersectWellsEnabled,
  setIntersectWellsEnabled,
  addBuffersToMap,
  addBuffersToMapAdjacentWells,
  map,
}) => {
  const [expandedItem, setExpandedItem] = useState(
    isWidthDown("sm", width) ? false : "npgcd-layers"
  );

  const [MapLayersAndControlSettings] = useFetchData(
    "map-layers-and-color-settings",
    [],
    false
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);

    if (panel === "search-radius") {
      onEnableSearchRadiusControl(isExpanded);
    } else {
      onEnableSearchRadiusControl(false);
    }
    if (panel === "adjacent-wells") {
      onEnableSearchRadiusControlAdjacentWells(isExpanded);
    } else {
      onEnableSearchRadiusControlAdjacentWells(false);
    }
  };

  return (
    <Container
      width={
        isWidthDown("sm", width) && !expandedItem
          ? "75px"
          : isWidthDown("sm", width) && expandedItem
          ? "225px"
          : "315px"
      }
    >
      {value === "attributes_search" && (
        <Accordion
          expanded={expandedItem === "basemaps"}
          onChange={handleChange("basemaps")}
        >
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Box alignItems="center" display="flex" gridColumnGap={8}>
              <MapIcon />
              {(isWidthUp("md", width) || expandedItem) && (
                <Typography variant="subtitle1">Basemaps</Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails
            maxheight={isWidthDown("xs", width) ? "258px" : "384px"}
          >
            <BasemapsControl
              items={basemaps}
              value={activeBasemap}
              onBasemapChange={onBasemapChange}
              filters={filters}
            />
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion
        expanded={expandedItem === "npgcd-layers"}
        onChange={handleChange("npgcd-layers")}
      >
        <AccordionSummary
          aria-controls="layers-content"
          id="layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <LayersIcon />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">NPGCD Data</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "258px" : "384px"}
        >
          <LayersControl
            MapLayersAndControlSettings={MapLayersAndControlSettings}
            items={layers.filter(
              (layer) => layer.lreProperties.legendParent === "NPGCD Data"
            )}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
            activeStyle={activeStyle}
            uniqueLegendGroups={[
              "North Plains Wells",
              "Groundwater Production Units (GPUs)",
              "District Section Block Survey",
              "Contour Maps",
              "Management Layers",
            ]}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "more-layers"}
        onChange={handleChange("more-layers")}
      >
        <AccordionSummary
          aria-controls="layers-content"
          id="layers-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <More />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">More Data</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "258px" : "384px"}
        >
          <LayersControl
            MapLayersAndControlSettings={MapLayersAndControlSettings}
            items={layers.filter(
              (layer) => layer.lreProperties.legendParent === "More Data"
            )}
            onLayerChange={onLayerChange}
            onOpacityChange={onOpacityChange}
            activeStyle={activeStyle}
            uniqueLegendGroups={[
              "TWDB Wells Database",
              "Statewide Boundaries",
              "CAD Parcels",
            ]}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "search-radius"}
        onChange={handleChange("search-radius")}
      >
        <AccordionSummary
          aria-controls="search-radius-content"
          id="search-radius-header"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <SearchRadiusIcon />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">Well Buffers Tool</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "335px" : "384px"}
        >
          <SearchRadiusControl
            bufferValues={bufferValues}
            onBufferValuesChange={onBufferValuesChange}
            onClearBuffers={onClearBuffers}
            onResetBuffers={onResetBuffers}
            addBuffersToMap={addBuffersToMap}
            map={map}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expandedItem === "adjacent-wells"}
        onChange={handleChange("adjacent-wells")}
      >
        <AccordionSummary
          aria-controls="adjacent-wells-content"
          id="adjacent-wells-content"
          expandIcon={<ExpandMore />}
        >
          <Box alignItems="center" display="flex" gridColumnGap={8}>
            <GroupWork />
            {(isWidthUp("md", width) || expandedItem) && (
              <Typography variant="subtitle1">Adjacent Wells Tool</Typography>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails
          maxheight={isWidthDown("xs", width) ? "335px" : "384px"}
        >
          <AdjacentWellsControl
            bufferValues={bufferValuesAdjacentWells}
            onBufferValuesChange={onBufferValuesChangeAdjacentWells}
            onClearBuffers={onClearBuffersAdjacentWells}
            onResetBuffers={onResetBuffersAdjacentWells}
            intersectWellsEnabled={intersectWellsEnabled}
            setIntersectWellsEnabled={setIntersectWellsEnabled}
            addBuffersToMap={addBuffersToMapAdjacentWells}
            map={map}
          />
        </AccordionDetails>
      </Accordion>
    </Container>
  );
};

export default withWidth()(MainControl);
