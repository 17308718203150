import React, { useEffect, useState } from "react";
// import "./styles.css";
import styled from "styled-components/macro";
import { isNullOrUndef } from "chart.js/helpers";
import { formatBooleanTrueFalse } from "../../../utils";
import Button from "@material-ui/core/Button";
import { Pagination } from "@material-ui/lab";
import { titleize } from "inflected";
import {
  Divider as MuiDivider,
  isWidthDown,
  Link,
  Typography as MuiTypography,
  withWidth,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

const PopupWrap = styled.div`
  height: ${({ height }) => height};
  overflow-y: scroll;
  width: ${({ width }) => width};
`;

const PopupTable = styled.table`
  border-radius: 5px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  width: 100%;
`;

const PopupRow = styled.tr`
  border-radius: 5px;
  &:nth-child(even) {
    background-color: #eee;
  }
`;

const PopupCell = styled.td`
  padding: 3px 6px;
  margin: 0;
`;

const PopupUl = styled.ul`
  list-style-type: none;
  margin: 0 !important;
  padding: 3px 0;
`;

const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const Popup = ({
  setDataVizVisible,
  setDataVizWellNumber,
  setDataVizGraphType,
  features,
  currentUser,
  layers,
  height = "200px",
  maxWidth = "380px",
  size = "medium",
  width,
}) => {
  const dataVizTypes = [
    "Battery Data",
    "Water Temperature Data",
    "Telemetry",
    "Winter Observations",
  ];

  function getUniqueFeatures(array, comparatorProperty1, comparatorProperty2) {
    const existingFeatureKeys = {};
    // Because features come from tiled vector data, feature geometries may be split
    // or duplicated across tile boundaries and, as a result, features may appear
    // multiple times in query results.
    //concat two ids to make a unique id
    return array.filter((el) => {
      if (
        existingFeatureKeys[
          el[comparatorProperty1] + el.layer[comparatorProperty2]
        ]
      ) {
        return false;
      } else {
        existingFeatureKeys[
          el[comparatorProperty1] + el.layer[comparatorProperty2]
        ] = true;
        return true;
      }
    });
  }

  const uniqueFeatures = getUniqueFeatures(features, "id", "id");

  const [page, setPage] = useState(1);
  const [feature, setFeature] = useState(uniqueFeatures?.[0] ?? []);
  const [excludeFields, setExcludeFields] = useState([]);
  const [titleField, setTitleField] = useState("");

  const handlePageChange = (e, p) => {
    setPage(p);
  };

  useEffect(() => {
    if (uniqueFeatures?.length) {
      setFeature(uniqueFeatures[page - 1]);
    }
  }, [uniqueFeatures, page]);

  useEffect(() => {
    const excludedFields = [
      ...(layers?.find((layer) => layer?.id === feature?.layer?.id)
        ?.lreProperties?.popup?.excludeFields || []),
      ...(currentUser?.isAdmin || currentUser?.isDeveloper
        ? []
        : layers?.find((layer) => layer?.id === feature?.layer?.id)
            ?.lreProperties?.popup?.adminOnlyFields || []),
    ];
    setExcludeFields(excludedFields);

    const title = layers?.find((layer) => layer?.id === feature?.layer?.id)
      ?.lreProperties?.popup?.titleField;
    setTitleField(
      (title && feature?.properties[title] && (
        <>
          <Typography variant="h4" style={{ fontWeight: 600 }}>
            {feature?.layer?.source === "north-plains-gcd-wells"
              ? "NPGCD Wells"
              : titleize(feature?.layer?.source)}
            :
          </Typography>
          <Divider my={1} mx={2} />
          <Typography mb={2} variant="h6">
            {feature?.properties[title]}
          </Typography>
        </>
      )) || (
        <Typography variant="h4" style={{ fontWeight: 600 }}>
          {titleize(feature?.layer?.source)}
        </Typography>
      )
    );
  }, [feature, layers, currentUser]);

  const addViewDataVizButtons = (key, value) => {
    if (dataVizTypes.includes(key)) {
      return (
        <>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              disabled={!value}
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                setDataVizVisible(true);
                setDataVizWellNumber(feature.properties.well_ndx);
                setDataVizGraphType(key);
              }}
            >
              View Data
            </Button>
          </span>
        </>
      );
    }
    return value;
  };

  const popupFormat = (key) => {
    if (key === "gpu_number") {
      return "GPU";
    }
    if (key === "gpu_name") {
      return "GPU Name";
    }
    if (key === "total_acres ") {
      return "Acres";
    }

    return titleize(key);
  };

  const popupData = excludeFields
    ? Object.entries(feature?.properties).reduce((acc, [key, value]) => {
        //MJB also removing entry if the value is an empty string, null, or undefined
        if (
          !excludeFields.includes(key) &&
          value !== "" &&
          !isNullOrUndef(value)
        ) {
          acc.push([key, value]);
        }
        return acc;
      }, [])
    : Object.entries(feature?.properties);

  if (feature?.layer?.source === "twdb-groundwater-wells") {
    popupData.unshift(
      [
        "Well Report",
        <Link
          target="_blank"
          rel="noreferrer"
          key={feature?.properties?.StateWellNumber}
          href={`https://www3.twdb.texas.gov/apps/waterdatainteractive//GetReports.aspx?Num=${feature?.properties?.StateWellNumber}&Type=GWDB`}
        >
          Click to Download
        </Link>,
      ],
      [
        "Scanned Documents",
        <Link
          target="_blank"
          rel="noreferrer"
          key={feature?.properties?.StateWellNumber}
          href={`http://s3.amazonaws.com/wellpdfs/documents/${feature?.properties?.StateWellNumber}/${feature?.properties?.StateWellNumber}.pdf`}
        >
          Click to Download
        </Link>,
      ]
    );
  }
  if (feature?.layer?.source === "twdb-drillers-reports") {
    const reports = [];

    if (feature?.properties?.PluggingReportTrackingNumber) {
      reports.unshift([
        "Plugging Report",
        <Link
          target="_blank"
          rel="noreferrer"
          key={feature?.properties?.PluggingReportTrackingNumber}
          href={`https://www3.twdb.texas.gov/apps/waterdatainteractive//GetReports.aspx?Num=${feature?.properties?.PluggingReportTrackingNumber}&Type=SDR-Plug`}
        >
          Click to Download
        </Link>,
      ]);
    }

    reports.unshift([
      "Drillers Report",
      <Link
        target="_blank"
        rel="noreferrer"
        key={feature?.properties?.WellReportTrackingNumber}
        href={`https://www3.twdb.texas.gov/apps/waterdatainteractive//GetReports.aspx?Num=${feature?.properties?.WellReportTrackingNumber}&Type=SDR-Well`}
      >
        Click to Download
      </Link>,
    ]);

    popupData.unshift(...reports);
  }

  if (feature?.layer?.source === "twdb-plugging-reports") {
    const reports = [];

    if (feature?.properties?.WellReportTrackingNumber) {
      reports.unshift([
        "Drillers Report",
        <Link
          target="_blank"
          rel="noreferrer"
          key={feature?.properties?.WellReportTrackingNumber}
          href={`https://www3.twdb.texas.gov/apps/waterdatainteractive//GetReports.aspx?Num=${feature?.properties?.WellReportTrackingNumber}&Type=SDR-Well`}
        >
          Click to Download
        </Link>,
      ]);
    }

    reports.unshift([
      "Plugging Report",
      <Link
        target="_blank"
        rel="noreferrer"
        key={feature?.properties?.PluggingReportTrackingNumber}
        href={`https://www3.twdb.texas.gov/apps/waterdatainteractive//GetReports.aspx?Num=${feature?.properties?.PluggingReportTrackingNumber}&Type=SDR-Plug`}
      >
        Click to Download
      </Link>,
    ]);

    popupData.unshift(...reports);
  }

  if (!popupData) return null;
  return (
    <>
      <PopupWrap
        height={isWidthDown("xs", width) ? "120px" : height}
        width={isWidthDown("sm", width) ? "205px" : maxWidth}
      >
        {titleField}
        <PopupTable>
          <tbody>
            {popupData?.map(([key, value]) => {
              if (dataVizTypes.includes(key) && !value) return null;
              return (
                <PopupRow key={key}>
                  <PopupCell>
                    <strong>
                      {feature.source === "north-plains-gcd-wells"
                        ? key
                        : popupFormat(key)}
                    </strong>
                  </PopupCell>
                  <PopupCell>
                    {/*MJB temporary logic to render links
              PROP_ID from Bell CAD Parcels for external id
              list_of_attachments from Clearwater Wells to link attachments
              parse renders string html element into
              */}
                    {typeof value === "string" &&
                    value.startsWith("https://") ? (
                      <PopupUl>
                        {value.split(",").map((item) => (
                          <Link
                            target="_blank"
                            rel="noreferrer"
                            key={item}
                            href={item}
                          >
                            Click to Download
                          </Link>
                        ))}
                      </PopupUl>
                    ) : ["state_links", "State Links"].includes(key) ? (
                      value.split(",").map((item) => (
                        <div key={item}>
                          <a
                            target="_blank"
                            href={item.split(";")[1]}
                            rel="noreferrer"
                          >
                            {item.split(";")[0]}
                          </a>
                        </div>
                      ))
                    ) : ["file_attachments", "File Attachments"].includes(
                        key
                      ) ? (
                      value.split(",").map((item) => (
                        <div key={item}>
                          <a
                            target="_blank"
                            href={item.split(";")[1]}
                            rel="noreferrer"
                          >
                            {item.split(";")[0]}
                          </a>
                        </div>
                      ))
                    ) : key === "gpu_number" ? (
                      <PopupUl>
                        {
                          <Link
                            color="secondary"
                            target="_blank"
                            rel="noreferrer"
                            key={key}
                            href={`/public-resources/tools/gpu-select/${value}`}
                          >
                            View Well Report
                          </Link>
                        }
                      </PopupUl>
                    ) : (
                      formatBooleanTrueFalse(addViewDataVizButtons(key, value))
                    )}
                  </PopupCell>
                </PopupRow>
              );
            })}
          </tbody>
        </PopupTable>
      </PopupWrap>
      <Pagination
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        count={uniqueFeatures.length}
        size={isWidthDown("sm", width) ? "small" : size}
        siblingCount={isWidthDown("sm", width) ? 0 : 1}
        page={page}
        variant="outlined"
        shape="rounded"
        color="primary"
        onChange={handlePageChange}
      />
    </>
  );
};

export default withWidth()(Popup);
