import { ButtonGroup, Button, isWidthDown, withWidth } from "@material-ui/core";

const WellStylesControl = ({ onChange, options, value, width }) => {
  return (
    <ButtonGroup
      orientation={isWidthDown("xs", width) ? "vertical" : "horizontal"}
    >
      {options.map((option) => {
        const active = value === option.value;
        return (
          <Button
            size={isWidthDown("xs", width) ? "small" : "medium"}
            key={option.value}
            color={active ? "primary" : "inherit"}
            onClick={() => onChange(option.value)}
            variant={active ? "contained" : "outlined"}
          >
            {option.display}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default withWidth()(WellStylesControl);
