export const BASEMAP_STYLES = [
  {
    style: "outdoors-v11",
    name: "Outdoors",
    url: "mapbox://styles/mapbox/outdoors-v11",
  },
  {
    style: "streets-v11",
    name: "Streets",
    url: "mapbox://styles/mapbox/streets-v11",
  },
  {
    style: "satellite-streets-v11",
    name: "Satellite",
    url: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  {
    style: "light-v10",
    name: "Light",
    url: "mapbox://styles/mapbox/light-v10",
  },
  {
    style: "dark-v10",
    name: "Dark",
    url: "mapbox://styles/mapbox/dark-v10",
  },
];

export const DEFAULT_MAP_CENTER = [-101.82512, 36.10962];

export const INIT_MAP_CONFIG = {
  style: BASEMAP_STYLES[0].url,
  center: DEFAULT_MAP_CENTER,
  zoom: 8.4,
  preserveDrawingBuffer: true,
};

export const WELLS_LAYER_ID = "north-plains-gcd-wells-circle";
export const WELLS_LABELS_LAYER_ID = "north-plains-gcd-wells-symbol";
export const INIT_FILTER_VALUES = {
  wellClass: {
    label: "Well Class",
    name: "wellClass",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "Well Class",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellType: {
    label: "Well Type",
    name: "wellType",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "Well Type",
    options: [],
    type: "multi-select",
    value: [],
  },
  wellStatus: {
    label: "Well Status",
    name: "wellStatus",
    layerId: WELLS_LAYER_ID,
    layerFieldName: "Well Status",
    options: [],
    type: "multi-select",
    value: [],
  },
  hasDtwWinterobs: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "Winter Observations",
    type: "boolean",
    value: false,
  },
  hasDtwTelemData: {
    layerId: WELLS_LAYER_ID,
    layerFieldName: "Telemetry",
    type: "boolean",
    value: false,
  },

  search: {
    layerId: WELLS_LAYER_ID,
    type: "select",
    value: "attributes_search",
  },
};

const layerId = "north-plains-gcd-wells-circle";
export const INIT_STYLE_VALUES = {
  wellClass: {
    id: "wellClass",
    layerId,
    layerFieldName: "Well Class",
    name: "Well Class",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "Well Class"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  wellType: {
    id: "wellType",
    layerId,
    layerFieldName: "Well Type",
    name: "Well Type",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "Well Type"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
  wellStatus: {
    loaded: true,
    id: "wellStatus",
    layerId,
    layerFieldName: "Well Status",
    name: "Well Status",
    options: [],
    type: "multi-select",
    value: [],
    paint: {
      "circle-color": [
        "match",
        ["get", "Well Status"],
        "No Values Available",
        "#000000",
        "#000000",
      ],
    },
  },
};
