import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Box, Paper, Typography } from "@material-ui/core";

import center from "@turf/center";
import Map from "./map";
import WellStylesControl from "./controls/wellStylesControl";
import ZoomInfo from "./controls/zoomInfo";
import Search from "./filters/search";
import FilterControl from "./filters/filterControl";
import Filter from "./filters/filter";

import { useMap } from "./hooks/useMap";
import useFilters from "./hooks/useFilters";
import useLayerStyles from "./hooks/useLayerStyles";
import { INIT_MAP_CONFIG } from "./constants";

import DisclaimerDialog from "./components/DisclaimerDialog";
import MeasurementsPopup from "../../components/map/components/MeasurementsPopup";
import DataViz from "./components/DataViz";
import MainControl from "./controls/mainControl/";

import PrintReportDialog from "./components/PrintReportDialog";
import { useReactToPrint } from "react-to-print";
import PrintMapFormat from "./components/PrintMapFormat";
import SplitButton from "../../components/SplitButton";
import MeasurementsControl from "./controls/MeasurementsControl";
import { isTouchScreenDevice } from "../../utils";
import DataVizControl from "./controls/dataVizControl";
import "./styles.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "mapbox-gl-controls/lib/controls.css";
import PopupControl from "./controls/popupControl";
import useSources from "./hooks/useSources";
import Loader from "../../components/Loader";
import { Modal } from "@mui/material";
import { polygon } from "@turf/helpers";

const FiltersBarRoot = styled(Paper)`
  height: 78px;
  align-items: center;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing(6)}px;
  padding: 12px 16px 12px 32px;
  overflow-y: scroll;
`;

const FiltersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2)}px;
`;

const FiltersSectionRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 100;
`;

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)}px;
  flex: 1 1 0;
`;

export const InfoContainer = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  position: absolute;
  left: 100px;
  bottom: 5px;
  padding: 5px 10px;
  margin: 0;
  font-size: 11px;
  line-height: 18px;
  border-radius: 3px;
  z-index: 2;
  display: none;

  &[data-visible="true"] {
    display: block;
  }
`;

export function getPolygonCenter(coordinates) {
  // Check if the input is a single polygon or an array of polygons
  const isSinglePolygon =
    Array.isArray(coordinates[0][0]) &&
    typeof coordinates[0][0][0] === "number";

  if (isSinglePolygon) {
    // Handle single polygon
    const poly = polygon(coordinates);
    const centerCoords = center(poly).geometry.coordinates;
    return centerCoords;
  } else {
    // Handle array of polygons
    let totalLat = 0;
    let totalLon = 0;

    coordinates.forEach((coords) => {
      const poly = polygon(coords);
      const centerCoords = center(poly).geometry.coordinates;
      totalLat += centerCoords[1];
      totalLon += centerCoords[0];
    });

    const averageLat = totalLat / coordinates.length;
    const averageLon = totalLon / coordinates.length;

    return [averageLon, averageLat];
  }
}

const PublicMap = () => {
  const mapContainer = useRef(null);
  const { sources } = useSources();
  const {
    activeBasemap,
    basemaps,
    layers,
    map,
    zoomLevel,
    searchRadiusBuffers,
    resetSearchRadiusBuffers,
    handleClearSearchRadiusBuffers,
    handleEnableSearchRadiusControl,
    updateSearchRadiusBuffers,
    updateLayerFilters,
    updateLayerStyles,
    updateLayerVisibility,
    updateLayerOpacity,
    updateBasemap,
    measurementsVisible,
    handleClearMeasurements,
    setMeasurementsVisible,
    polygonRef,
    radiusRef,
    pointRef,
    lineRef,
    measurementsContainerRef,
    dataVizVisible,
    setDataVizVisible,
    dataVizWellNumber,
    dataVizGraphType,
    eventsRegistered,
    isMapLoaded,
    popupVisibleRef,
    popupVisible,
    setPopupVisible,
    selectedGpus,
    setSelectedGpus,
    searchRadiusBuffersAdjacentWells,
    handleEnableSearchRadiusControlAdjacentWells,
    updateSearchRadiusBuffersAdjacentWells,
    handleClearSearchRadiusBuffersAdjacentWells,
    resetSearchRadiusBuffersAdjacentWells,
    intersectWellsEnabled,
    setIntersectWellsEnabled,
    addBuffersToMap,
    addBuffersToMapAdjacentWells,
    gpuIdDisplayRef,
  } = useMap(mapContainer, INIT_MAP_CONFIG, sources);
  const {
    filterValues,
    handleFilterValues,
    handleSelectAll,
    handleSelectNone,
    filtersRawData,
  } = useFilters({ onFilterChange: updateLayerFilters });
  const { activeStyle, handleActiveStyle, styleOptions } = useLayerStyles({
    onLayerStyleChange: updateLayerStyles,
    isMapLoaded,
    filtersRawData,
  });

  const onClearSelected = () => {
    selectedGpus.forEach((gpu) => {
      map.setFeatureState(
        {
          source: "gis-gpu-polygons",
          id: gpu,
        },
        {
          selected: false,
        }
      );
    });
    setSelectedGpus([]);
  };

  const handleSearchSelect = (result) => {
    if (result?.gpu_geometry?.coordinates) {
      setSelectedGpus((prevSelectedGpus) => [
        ...prevSelectedGpus,
        result.index,
      ]);

      map.setFeatureState(
        {
          source: "gis-gpu-polygons",
          id: result.index,
        },
        {
          selected: true,
        }
      );

      const center = getPolygonCenter(result?.gpu_geometry?.coordinates);
      map?.flyTo({ center: center, zoom: 14 });
    }
    if (result?.location_geometry?.coordinates) {
      map?.flyTo({ center: result?.location_geometry?.coordinates, zoom: 16 });
    }
  };

  const printRef = useRef();
  const [printReportDialogOpen, setPrintReportDialogOpen] = useState(false);
  const [title, setTitle] = useState("");
  const handlePrintMapClick = useReactToPrint({
    content: () => printRef.current,
  });

  const handleSavePNG = () => {
    const a = document.createElement("a");
    a.href = map.getCanvas().toDataURL();
    a.download = "map.png";
    document.body.appendChild(a);
    a.click();
  };

  const splitButtonOptions = ["Print PDF", "Save PNG"];
  const handleSplitButtonClick = (index) => {
    if (![0, 1].includes(index)) return;

    if (index === 0) {
      setPrintReportDialogOpen(true);
    } else if (index === 1) {
      handleSavePNG();
    }
  };

  const getMoreFiltersCount = (filterValues) => {
    const keys = ["hasDtwWinterobs", "hasDtwTelemData"];
    return keys.filter((key) => filterValues[key].value).length;
  };

  return (
    <>
      <Modal open={!sources.length}>
        <div style={{ height: "100%" }}>
          <Loader height="120px" width="120px" />
        </div>
      </Modal>
      {process.env.NODE_ENV !== "development" && sources.length && (
        <DisclaimerDialog />
      )}
      {/*<RightDrawer>should I do this</RightDrawer>*/}
      <FiltersBarRoot>
        <FiltersContainer>
          <FiltersSectionRow>
            <Search
              onSelect={handleSearchSelect}
              sources={sources}
              onClearSelected={onClearSelected}
            />
          </FiltersSectionRow>
        </FiltersContainer>

        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="125px"
              appliedCount={filterValues?.wellClass?.value?.length}
              label="Well Class"
            >
              <Filter
                label="Well Class"
                name="wellClass"
                onChange={handleFilterValues}
                onSelectAll={handleSelectAll}
                onSelectNone={handleSelectNone}
                options={filterValues?.wellClass?.options}
                type={filterValues?.wellClass?.type}
                value={filterValues?.wellClass?.value}
              />
            </FilterControl>
            <FilterControl
              width="125px"
              appliedCount={filterValues?.wellType?.value?.length}
              label="Well Type"
            >
              <Filter
                label="Well Type"
                name="wellType"
                onChange={handleFilterValues}
                onSelectAll={handleSelectAll}
                onSelectNone={handleSelectNone}
                options={filterValues?.wellType?.options}
                type={filterValues?.wellType?.type}
                value={filterValues?.wellType?.value}
              />
            </FilterControl>
            <FilterControl
              width="130px"
              appliedCount={filterValues?.wellStatus?.value?.length}
              label="Well Status"
            >
              <Filter
                label="Well Status"
                name="wellStatus"
                onChange={handleFilterValues}
                onSelectAll={handleSelectAll}
                onSelectNone={handleSelectNone}
                options={filterValues?.wellStatus?.options}
                type={filterValues?.wellStatus?.type}
                value={filterValues?.wellStatus?.value}
              />
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="95px"
              appliedCount={getMoreFiltersCount(filterValues)}
              label="Toggles"
            >
              <Box display="flex" flexDirection="column">
                <Filter
                  label="Has Winter Observations"
                  name="hasDtwWinterobs"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasDtwWinterobs?.value}
                />
                <Filter
                  label="Has Telemetry"
                  name="hasDtwTelemData"
                  onChange={handleFilterValues}
                  type="boolean"
                  value={filterValues?.hasDtwTelemData?.value}
                />
              </Box>
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <FilterControl
              width="250px"
              label={`Color wells by ${activeStyle?.name}`}
            >
              <Typography variant="subtitle1" gutterBottom>
                Color wells by
              </Typography>
              <WellStylesControl
                label="Color wells by"
                name="wellStyles"
                onChange={handleActiveStyle}
                options={styleOptions}
                value={activeStyle?.id}
              />
            </FilterControl>
          </FiltersContainer>
        </FiltersSection>

        <FiltersSection>
          <FiltersContainer>
            <>
              <SplitButton
                options={splitButtonOptions}
                handleExportClick={handleSplitButtonClick}
              />
              <PrintReportDialog
                downloadCallback={handlePrintMapClick}
                setPrintReportDialogOpen={setPrintReportDialogOpen}
                printReportDialogOpen={printReportDialogOpen}
                title={title}
                setTitle={setTitle}
              />
            </>
          </FiltersContainer>
        </FiltersSection>
      </FiltersBarRoot>
      <Map ref={mapContainer}>
        <MeasurementsPopup
          measurementsContainerRef={measurementsContainerRef}
          radiusRef={radiusRef}
          polygonRef={polygonRef}
          pointRef={pointRef}
          lineRef={lineRef}
          onHide={() => setMeasurementsVisible(false)}
          onClear={handleClearMeasurements}
        />

        <InfoContainer ref={gpuIdDisplayRef} />

        {eventsRegistered && (
          <MainControl
            activeStyle={activeStyle}
            activeBasemap={activeBasemap}
            basemaps={basemaps}
            bufferValues={searchRadiusBuffers}
            layers={layers}
            onBasemapChange={updateBasemap}
            filters={filterValues}
            onBufferValuesChange={updateSearchRadiusBuffers}
            onClearBuffers={handleClearSearchRadiusBuffers}
            onEnableSearchRadiusControl={handleEnableSearchRadiusControl}
            onLayerChange={updateLayerVisibility}
            onOpacityChange={updateLayerOpacity}
            onResetBuffers={resetSearchRadiusBuffers}
            value={filterValues?.search?.value}
            bufferValuesAdjacentWells={searchRadiusBuffersAdjacentWells}
            onBufferValuesChangeAdjacentWells={
              updateSearchRadiusBuffersAdjacentWells
            }
            onClearBuffersAdjacentWells={
              handleClearSearchRadiusBuffersAdjacentWells
            }
            onEnableSearchRadiusControlAdjacentWells={
              handleEnableSearchRadiusControlAdjacentWells
            }
            onResetBuffersAdjacentWells={resetSearchRadiusBuffersAdjacentWells}
            intersectWellsEnabled={intersectWellsEnabled}
            setIntersectWellsEnabled={setIntersectWellsEnabled}
            addBuffersToMap={addBuffersToMap}
            map={map}
            addBuffersToMapAdjacentWells={addBuffersToMapAdjacentWells}
          />
        )}
        {process.env.NODE_ENV === "development" && (
          <ZoomInfo zoomLevel={zoomLevel} />
        )}
        <DataVizControl
          open={dataVizVisible}
          onClose={() => setDataVizVisible(!dataVizVisible)}
        />
        <PopupControl
          open={popupVisibleRef.current}
          onClose={() => setPopupVisible(!popupVisible)}
          top={65}
        />
        {!popupVisibleRef.current && (
          <div
            dangerouslySetInnerHTML={{
              __html: "<style>.mapboxgl-popup { display: none; }</style>",
            }}
          />
        )}

        <DataViz
          open={dataVizVisible}
          dataVizWellNumber={dataVizWellNumber}
          dataVizGraphType={dataVizGraphType}
          onClose={() => setDataVizVisible(false)}
        />
        {!measurementsVisible && !isTouchScreenDevice() && (
          <MeasurementsControl
            open={measurementsVisible}
            onToggle={() => setMeasurementsVisible(!measurementsVisible)}
            right={49}
            bottom={30}
          />
        )}
      </Map>

      {eventsRegistered && printReportDialogOpen && (
        <span
          style={{
            display: "none",
            width: "100%",
          }}
        >
          <PrintMapFormat
            ref={printRef}
            title={title}
            mapImg={map.getCanvas().toDataURL("image/png")}
            map={map}
          />
        </span>
      )}
    </>
  );
};

export default PublicMap;
