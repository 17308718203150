import React from "react";
import ReactDOM from "react-dom";
import { json } from "d3-request";
import { Tooltip } from "@mui/material";

class ToggleBasemapControl {
  constructor(base, icon, label) {
    this.base = base;
    this.icon = icon;
    this.label = label;

    this.handleClick = this.handleClick.bind(this);
  }

  swapStyle(styleID) {
    const currentStyle = this._map.getStyle();

    json(
      `https://api.mapbox.com/styles/v1/mapbox/${styleID}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
      (newStyle) => {
        newStyle.sources = Object.assign(
          {},
          currentStyle.sources,
          newStyle.sources
        );

        const labelIndex = newStyle.layers.findIndex(
          (el) => el.id === "locations"
        );
        const appLayers = currentStyle.layers.filter(
          (el) =>
            el.source &&
            el.source !== "mapbox://mapbox.satellite" &&
            el.source !== "composite"
        );
        appLayers.forEach((layer) => {
          newStyle.layers.splice(labelIndex, 0, layer);
        });
        this._map.setStyle(newStyle);
      }
    );
  }

  onAdd(map) {
    this._map = map;
    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group";

    const icon = (
      <button
        type="button"
        className="material-icons"
        style={{ verticalAlign: "middle", cursor: "pointer" }}
      >
        {this.icon}
      </button>
    );

    const tooltipContent = (
      <Tooltip title={this.label} placement="left" arrow>
        <span onClick={this.handleClick}>{icon}</span>
      </Tooltip>
    );

    ReactDOM.render(tooltipContent, this._container);

    return this._container;
  }

  handleClick() {
    this.swapStyle(this.base);
  }

  onRemove() {
    ReactDOM.unmountComponentAtNode(this._container);
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  }
}

export default ToggleBasemapControl;
